<template>
  <div class="main-page">
    <template v-if="showHeader">
      <section class="page-section mb-3">
        <div class="container-fluid">
          <div class="grid justify-content-between">
            <div class="col-12 md:col-6 lg:col-5">
              <div class="">
                <div class="flex align-items-center">
                  <div>
                    <div class="text-2xl text-primary font-bold">
                      Add New Blocked User
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </template>
    <section class="page-section">
      <div class="container-fluid">
        <div class="grid">
          <div class="md:col-8 sm:col-12 comp-grid">
            <div :class="{ card: !isSubPage }" class="">
              <div>
                <form
                  ref="observer"
                  tag="form"
                  @submit.prevent="startSaveRecord()"
                >
                  <div class="grid">
                    <div class="col-12">
                      <div class="formgrid grid">
                        <div class="col-12 md:col-3">Msisdn *</div>
                        <div class="col-12 md:col-9">
                          <InputText
                            ref="ctrlmsisdn"
                            v-model.trim="formData.msisdn"
                            label="Msisdn"
                            type="text"
                            placeholder="Enter Msisdn"
                            class="w-full"
                            pattern="^[0-9]+$"
                            maxlength="12"
                            v-tooltip.top.focus="'Eg: 233549112267'"
                            :class="getErrorClass('msisdn')"
                          >
                          </InputText>
                        </div>
                      </div>
                    </div>
                    <div class="col-12">
                      <div class="formgrid grid">
                        <div class="col-12 md:col-3">Reason</div>
                        <div class="col-12 md:col-9">
                          <Textarea
                            :class="getErrorClass('reason')"
                            class="w-full"
                            ref="ctrlreason"
                            rows="3"
                            v-model="formData.reason"
                            placeholder="Reason for blocking user"
                            type="textarea"
                          >
                          </Textarea>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div v-if="showSubmitButton" class="text-center my-3">
                    <Button
                      class="p-button-primary"
                      type="submit"
                      label="Submit"
                      icon="pi pi-send"
                      :loading="saving"
                    />
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>
<script>
//[--PAGE-IMPORT-STATEMENT--]
import { PageMixin } from "../../mixins/page.js";
import { AddPageMixin } from "../../mixins/addpage.js";
import { mapActions, mapGetters, mapState } from "vuex";
import useVuelidate from "@vuelidate/core";
import {
  required,
  email,
  sameAs,
  minLength,
  maxLength,
  minValue,
  maxValue,
  numeric,
  integer,
  decimal,
  ipAddress,
} from "@vuelidate/validators";
export default {
  setup() {
    return { v$: useVuelidate() };
  },
  name: "addBlockedusersPage",
  components: {
    //[--PAGE-COMPONENT-NAME--]
  },
  mixins: [PageMixin, AddPageMixin],
  props: {
    pageName: {
      type: String,
      default: "blockedusers",
    },
    routeName: {
      type: String,
      default: "blockedusersadd",
    },
    apiPath: {
      type: String,
      default: "blockedusers/add",
    },
  },
  data() {
    return {
      formData: {
        msisdn: "",
        reason: "",
      },
    };
  },
  computed: {
    pageTitle: {
      get: function () {
        return "Add Blocked Users";
      },
    },
  },
  validations() {
    let formData = {
      msisdn: { required },
      reason: {},
    };
    return { formData };
  },
  methods: {
    ...mapActions("blockedusers", ["saveRecord"]),
    async startSaveRecord() {
      this.submitted = true;
      const isFormValid = !this.v$.$invalid;
      if (!isFormValid) {
        this.flashMsg("Form is invalid", "", "error");
        return;
      }
      this.saving = true;
      let payload = this.normalizedFormData();
      let url = this.apiUrl;
      let data = { url, payload };
      this.saveRecord(data).then(
        (response) => {
          this.record = response.data ?? {};
          this.id = this.record["recordid"] ?? null;
          this.saving = false;
          this.resetForm();
          this.submitted = false;
          this.closeDialogs(); // close page dialog that if opened
          this.flashMsg(this.msgAfterSave);
          this.$emit("submitted", this.record);
          if (this.redirect) this.navigateTo(`/blockedusers`);
        },
        (response) => {
          this.saving = false;
          this.showPageRequestError(response);
        }
      );
    },
    resetForm() {
      this.formData = {
        msisdn: "",
        reason: "",
      };
      this.updateFormData();
      //this.$EventBus.$emit("resetForm");
    },
    getErrorClass(field) {
      return {
        "p-invalid": this.v$.formData[field].$invalid && this.submitted,
      };
    },
  },
  watch: {},
  async mounted() {},
  async created() {},
};
</script>
<style scoped></style>
